<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1 v-if="!isCasUser">{{ $t('message.presentation_my_ecoffre') }}</h1>
        <h1 v-if="isCasUser">{{ $t('message.presentation_coffre_code_budget') }}</h1>
      </header>

      <p class="mb-5" v-if="!isCasUser">
        {{ $t('message.presentation_paragraph_1') }}
        <a :href="$t('message.ecoffre_url')" target="_blank">{{ $t('message.ecoffre_url') }}</a>.
        {{ $t('message.presentation_paragraph_2') }}
      </p>

      <p class="mb-5" v-if="isCasUser">
        {{ $t('message.presentation_paragraph_3') }}
        <a :href="$t('message.code_budget_user_guide_url')" target="_blank">{{ $t('message.code_budget_user_guide_2') }}</a>
      </p>

      <br>
      <br>

      <button type="button" @click="videoDialogState = true" class="v-btn v-btn--text v-size--default blue--text text--darken-1" v-if="!isCasUser">
        <span class="v-btn__content">{{ $t('message.view_presentation_videos') }}</span>
      </button>

      <VideoDialog
        :key="Date.now() + 11"
        :video-dialog-state="videoDialogState"
        @closeVideoDialog="closeVideoDialog($event)"
      ></VideoDialog>

      <br>
      <br>

      <h3 class="text-center text-gray-600">
        {{ $t('message.frequently_asked_questions') }}
      </h3>

      <br>

      <v-tabs
        v-model="tab"
        background-color="transparent"
        class=""
        :centered="centered"
        :grow="grow"
        :right="right"
        :icons-and-text="icons"
        show-arrows
      >
        <v-tab
          v-for="category in categories"
          :key="category"
          :href="`#${category}`"
        >
          {{ category }}
          <v-icon v-if="icons">mdi-phone</v-icon>
        </v-tab>

        <v-tab-item
          v-for="(category, keyCategory) in categories"
          v-bind:key="keyCategory"
          :value="category"
        >
          <v-card flat tile>
            <v-card-text>
              <div class="mdl-tabs__panel is-active" :id="'qr-cat-' + (category.replaceAll(' ', '_') + keyCategory) + '-container'">
                <div class="column">
                  <div v-for="(faq, keyFaq) in faqs" v-bind:key="keyFaq">
                    <div class="qr" v-if="resolveCategoryLabel(faq) === category && faq.column === 1">
                      <div v-html="resolveQuestion(faq)" :id="'q-' + keyFaq +'-cat-' + (category.replaceAll(' ', '_') + keyCategory)" class="btn question collapsed" data-toggle="collapse" :data-target="'#collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)" :aria-expanded="false" :aria-controls="'collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)">
                        <i class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </div>
                      <div :id="'collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)" class="collapse" :aria-labelledby="'q-' + keyFaq +'-cat-' + (category.replaceAll(' ', '_') + keyCategory)" :data-parent="'#qr-cat-' + (category.replaceAll(' ', '_') + keyCategory) + '-container'">
                        <div class="response" v-html="resolveResponse(faq)"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div v-for="(faq, keyFaq) in faqs" v-bind:key="keyFaq">
                    <div class="qr" v-if="resolveCategoryLabel(faq) === category && faq.column === 2">
                      <div v-html="resolveQuestion(faq)" :id="'q-' + keyFaq +'-cat-' + (category.replaceAll(' ', '_') + keyCategory)" class="btn question collapsed" data-toggle="collapse" :data-target="'#collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)" :aria-expanded="false" :aria-controls="'collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)">
                        <i class="mdi mdi-arrow-down-drop-circle-outline"></i>
                      </div>
                      <div :id="'collapse-' + keyFaq + '-cat-' + (category.replaceAll(' ', '_') + keyCategory)" class="collapse" :aria-labelledby="'q-' + keyFaq +'-cat-' + (category.replaceAll(' ', '_') + keyCategory)" :data-parent="'#qr-cat-' + (category.replaceAll(' ', '_') + keyCategory) + '-container'">
                        <div class="response" v-html="resolveResponse(faq)"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import Vue from 'vue'
import config from '../../config'
import VideoDialog from '../../components/Dialog/Video/VideoDialog'

export default {
  name: 'Faq',
  data: () => ({
    faqs: [],
    loading: false,
    tab: null,
    icons: false,
    centered: true,
    grow: false,
    right: false,
    videoDialogState: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - ' + this.$t('message.faq')
  },
  mounted () {
    this.getFaqs()
  },
  computed: {
    locale () {
      return localStorage.getItem('locale') || 'fr'
    },
    categories () {
      const categories = []

      for (let i = 0; i < this.faqs.length; i++) {
        const faq = this.faqs[i]

        let locale = this.locale

        if (!faq.translations[this.locale] || !faq.translations[this.locale].categoryLabel) {
          locale = 'fr'
        }

        categories[faq.category] = faq.translations[locale].categoryLabel
      }

      return categories.filter((category) => typeof category === 'string')
    },
    isCasUser () {
      let casUser = this.$store.getters.isCasUser
      if (this.$route.query.type && this.$route.query.type === 'cfrb') {
        casUser = true
      }
      return casUser
    }
  },
  methods: {
    resolveCategoryLabel (faq) {
      let locale = this.locale

      if (!faq.translations[this.locale] || !faq.translations[this.locale].categoryLabel) {
        locale = 'fr'
      }

      return faq.translations[locale].categoryLabel
    },
    resolveQuestion (faq) {
      let locale = this.locale

      if (!faq.translations[this.locale] || !faq.translations[this.locale].question) {
        locale = 'fr'
      }

      return faq.translations[locale].question
    },
    resolveResponse (faq) {
      let locale = this.locale

      if (!faq.translations[this.locale] || !faq.translations[this.locale].response) {
        locale = 'fr'
      }

      return faq.translations[locale].response
    },
    getFaqs () {
      const options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }

      const currentCompany = this.$store.getters.getCurrentCompany

      options.params = {
        locale: localStorage.getItem('locale')
      }

      if (currentCompany) {
        options.params = {
          company: this.$store.getters.getCurrentCompany.id,
          locale: localStorage.getItem('locale')
        }
      }

      if (this.$route.query.type && this.$route.query.type === 'cfrb') {
        options.params = {
          company: 'cfrb',
          locale: localStorage.getItem('locale')
        }
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/faqs', options)
        .then(response => {
          if (response.status === 200) {
            const faqs = response.data

            if (faqs) {
              for (let i = 0; i < faqs.length; i++) {
                const faq = faqs[i]

                faq.column = (i % 2 === 0) ? 1 : 2
              }

              this.faqs = faqs
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    },
    closeVideoDialog () {
      this.videoDialogState = false
    }
  },
  components: {
    VideoDialog
  }
}
</script>
